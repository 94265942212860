let clickCount = 0; // Кількість натискань на кнопку
let currentDegree = 0; // Поточний кут обертання

document.getElementById("spinButton").addEventListener("click", function () {
  clickCount++; // Збільшуємо кількість натискань на кнопку
  var wheel = document.querySelector("#wheel"); // Звертаємося до SVG
  wheel.style.transition = "transform 2s";

  if (clickCount === 1) {
    currentDegree += 700; // Додаємо 700 градусів для першого натискання
  } else if (clickCount === 2) {
    currentDegree += 950; // Додаємо 950 градусів для другого натискання
  }

  wheel.style.transform = `rotate(${currentDegree}deg)`; // Застосовуємо обертання

  function showModal() {
    document.getElementById("myModal").style.display = "block";
  }

  // Закриття модального вікна
  document.getElementById("closeModal").addEventListener("click", function () {
    document.getElementById("myModal").style.display = "none";
  });

  wheel.ontransitionend = function () {
    if (clickCount === 1) {
      document.getElementById("message").style.display = "flex"; // Показуємо повідомлення "Вітаємо"
    } else if (clickCount === 2) {
      document.getElementById("winMessage").style.display = "flex"; // Показуємо повідомлення "Ви виграли"
    }
    // Вимикаємо обробник події після другого натискання
    if (clickCount >= 2) {
      wheel.ontransitionend = null;
    }
    if (clickCount === 2) {
      setTimeout(showModal, 1000); // Показати модальне вікно через 1 секунду
    }
  };
});
